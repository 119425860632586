import './index.scss'

import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'

import device from 'current-device'

let $menuEl = $('.comp-menu')
let $menuGroupElArr = $('.comp-menu-list-group')
let $menuPhoneSubElArr = $('.comp-menu-phonesub')
let $openElArr = $('[data-open="menu"]')
let $closeElArr = $('[data-close="menu"]')
let $toggleElArr = $('[data-toggle="menu"]')
let $bindElArr = $('[data-bind="menu"]')

let pcSubSwiper = new Swiper('.comp-menu-pcsub', {
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  on: {
    transitionStart () {
      $menuGroupElArr.removeClass('active').eq(this.realIndex).addClass('active')
    }
  }
})

// 初始化位置
let defaultI = $menuGroupElArr.index($menuGroupElArr.filter('.active'))

pcSubSwiper.slideTo(defaultI)
$menuPhoneSubElArr.hide()
$menuPhoneSubElArr.eq(defaultI).slideDown()

$openElArr.on('click', function (e) {
  e.preventDefault()
  $menuEl.addClass('show')
  $bindElArr.addClass('active')
})
$closeElArr.on('click', function (e) {
  e.preventDefault()
  $menuEl.removeClass('show')
  $bindElArr.removeClass('active')
})
$toggleElArr.on('click', function (e) {
  e.preventDefault()
  $menuEl.toggleClass('show')
  $bindElArr.toggleClass('active')
})
$menuGroupElArr.on('click', function (e) {
  let $targetEl = $(this)
  let i = $menuGroupElArr.index($targetEl)
  
  $menuPhoneSubElArr.stop().slideUp(200).eq(i).stop().slideDown(200)
})
$menuGroupElArr.on('mouseenter', function () {
  let $target = $(this)
  let i = $menuGroupElArr.index($target)
  
  pcSubSwiper.slideTo(i)
})

// 手机端一级阻止导航链接跳转
$('.comp-menu-list-item').on('click', function (e) {
  if (device.mobile()) {
    e.preventDefault()
  }
})
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!text) var text = '';
;
__p += '\r\n<a class="comp-link" href="#">\r\n  <p>\r\n    ' +
((__t = ( text )) == null ? '' : __t) +
'\r\n  </p>\r\n</a>\r\n';

}
return __p
}
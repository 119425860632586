module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath = '';
if (!navStyle) var navStyle = 1;
;
__p += '\n\n<div class="comp-banner">\n  <div class="container">\n    <div class="row">\n      <div class="col-sm-6 col-xs-12">\n        <div class="comp-banner-text">\n          <div class="comp-banner-title">\n            ' +
((__t = ( require('../../components/title/index.ejs')({
              size: 'l',
              cn: '关于我们',
              en: '/ ABOUT US'
            }) )) == null ? '' : __t) +
'\n          </div>\n          <div class="comp-banner-nav">\n            ';
 if (navStyle === 1) { ;
__p += '\n              <!-- 第一种 banner nav 形式，使用点 点缀的-->\n              <a class="comp-banner-item1 active" href="#">公司介绍</a>\n              <a class="comp-banner-item1" href="#">公司介绍</a>\n              <a class="comp-banner-item1" href="#">公司介绍</a>\n              <a class="comp-banner-item1" href="#">公司介绍</a>\n              <a class="comp-banner-item1" href="#">公司介绍</a>\n              <a class="comp-banner-item1" href="#">公司介绍</a>\n              <a class="comp-banner-item1" href="#">公司介绍</a>\n            ';
 } ;
__p += '\n            ';
 if (navStyle === 2) { ;
__p += '\n              <!-- 第一种 banner nav 形式，使用线 点缀的-->\n              <a class="comp-banner-item2 active" href="#">公司介绍</a>\n              <a class="comp-banner-item2" href="#">公司介绍</a>\n              <a class="comp-banner-item2" href="#">公司介绍</a>\n            ';
 } ;
__p += '\n          </div>\n        </div>\n      </div>\n      <div class="col-sm-6 col-xs-12">\n        <div class="comp-banner-pic">\n          <img src="' +
((__t = ( bannerPath )) == null ? '' : __t) +
'" alt="">\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
}
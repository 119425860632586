module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!text) var text = '详细了解'
if (!theme) var theme = 'blue'
;
__p += '\n\n<div class="comp-more ' +
((__t = ( theme )) == null ? '' : __t) +
'">\n  ' +
((__t = ( require('../link/index.ejs')({
    text
  }) )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}
import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'

let $groupElArr = $('.group')

$groupElArr.each(function (i, el) {
  let $el = $(el)
  let $swiperEl = $el.find('.product-swiper')
  let $tabEl = $el.find('.group-header-tab')
  let $tabItemElArr = $tabEl.find('a')
  let $selectEl = $el.find('.group-header-select')
  let $selectSubEl = $selectEl.find('.group-header-select-sub')
  let $selectItemElArr = $selectEl.find('a')
  let $selectLabelEl = $selectEl.find('.group-header-select-label')
  
  if ($swiperEl.length) {
    let swiper = new Swiper($swiperEl, {
      autoHeight: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      simulateTouch: false,
      on: {
        transitionStart () {
          $tabItemElArr.removeClass('active').eq(this.realIndex).addClass('active')
        }
      }
    })
    
    // tab
    $tabItemElArr.on('click', function (e) {
      let $el = $(e.target)
      let i = $tabItemElArr.index($el)
      
      e.preventDefault()
      
      swiper.slideTo(i)
    })
    
    // select
    $selectEl.on('click', function () {
      $selectSubEl.slideToggle(200)
    })
    $selectItemElArr.on('click', function (e) {
      let $this = $(this)
      let i = $selectItemElArr.index($this)
      
      e.preventDefault()
      swiper.slideTo(i)
      $selectLabelEl.html($this.html())
    })
  }
})
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<section class="comp-partner">\n  <div class="row space-6">\n    ';
 for (var i = 0; i < 15; i++) { ;
__p += '\n      <div class="comp-partner-col">\n        <div class="comp-partner-item">\n          <img class="comp-partner-pic" src="' +
((__t = ( require(`./images/partner (${ i + 1 }).png`) )) == null ? '' : __t) +
'" alt="">\n        </div>\n      </div>\n    ';
 } ;
__p += '\n  </div>\n</section>\n';

}
return __p
}
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header class="comp-header">\n  <div class="container">\n    <div class="comp-header-body">\n      <a class="comp-header-logo" href="#">\n        <img src="' +
((__t = ( require('./images/header-logo.png') )) == null ? '' : __t) +
'" alt="">\n      </a>\n      <div class="comp-header-last">\n        <div class="comp-header-hamburger" data-open="menu">\n          <div class="hamburger hamburger-6" data-build="menu">\n            <div></div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="comp-header-title">\n    <p>智创赋能 同担共享</p>\n    <div class="banner-pagination"></div>\n  </div>\n</header>\n';

}
return __p
}
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!cn) var cn = '';
if (!en) var en = '';
if (!size) var size = 'm'
;
__p += '\r\n\r\n<div class="comp-title ' +
((__t = ( size )) == null ? '' : __t) +
'">\r\n  <p class="comp-title-cn">\r\n    ' +
((__t = ( cn )) == null ? '' : __t) +
'\r\n  </p>\r\n  <p class="comp-title-en">\r\n    ' +
((__t = ( en )) == null ? '' : __t) +
'\r\n  </p>\r\n</div>\r\n';

}
return __p
}
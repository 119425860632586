import './index.scss'

import 'tooltipster/dist/js/tooltipster.bundle.min'
import 'tooltipster/dist/css/tooltipster.bundle.min.css'
import 'tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-borderless.min.css'

$(document).ready(function () {
  $('[tooltip]').tooltipster({
    theme: 'tooltipster-borderless',
    side: 'left'
  })
})
